import React, { useEffect } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { handleSettingsSubmit } from 'utils/handle-settings-submit';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { TextInput } from '@rexlabs/text-input';

function getWebsiteId(props) {
  return props?.match?.params?.websiteId;
}

const settingQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: staff_page_info) {
    key
    value
    label
    type
    category
  }
}`;

const staffpageinfo = {
  name: 'staffpageinfo',
  mapPropsToValues: (props) => {
    const { settings } = props;
    const settingsData = settings?.list?.items;

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        switch (object.type) {
          case 'image':
            return {
              [object.key]: object?.value?.data
                ? object?.value.data
                : object?.value
            };
          default:
            return { [object.key]: object?.value ? object?.value[0] : null };
        }
      })
    );
    return {
      ...data
    };
  },
  handleSubmit: handleSettingsSubmit,
  asyncValuesReady: (props) => {
    return props.settings.list.status === 'loaded';
  }
};

const SectionTitle = (props) => {
  return (
    <Box
      flex={1}
      flexDirection={'row'}
      padding='10px'
      alignItems={'center'}
      marginTop='20px'
    >
      <h2
        style={{
          fontSize: '18px',
          fontWeight: '700',
          marginRight: '20px'
        }}
      >
        {props.title}
      </h2>
    </Box>
  );
};

function StaffPageInfo(props) {
  const { settings } = props;

  const trackSave = () => {
    window.analytics?.track?.('siteloft.design', {
      action: 'updated staff page info',
      location: 'Staff Page Info'
    });
  };

  useEffect(() => {
    window.analytics?.page?.('Staff Page Info');
  }, []);

  return (
    <Box flex={1} style={{ padding: '20px 20px 100px 20px', overflow: 'auto' }}>
      <RenderLoading isLoading={settings.list.status !== 'loaded'}>
        <ReactForms
          initialValues={staffpageinfo.mapPropsToValues(props)}
          handleSubmit={(values) =>
            staffpageinfo.handleSubmit(values, { props: props })
          }
        >
          {({ submitForm, isSubmitting }) => (
            <Form name='customCss' style={{ width: '100%' }}>
              <Box
                flex={1}
                flexDirection={'row'}
                padding='10px'
                alignItems={'center'}
              >
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: '700',
                    marginRight: '20px'
                  }}
                >
                  {' '}
                  Staff Page Settings{' '}
                </h1>

                <PrimaryButton
                  onClick={(e) => {
                    trackSave();
                    submitForm(e);
                  }}
                  isLoading={isSubmitting}
                >
                  Save
                </PrimaryButton>
              </Box>

              <SectionTitle title={'Optional Listing Slider Links'} />

              <Box padding='10px' flex={1}>
                <Field
                  name='staff_listing_slider_default_link'
                  label='Default'
                  Input={TextInput}
                  optional={true}
                  HelpTooltipContent={() => "Defaults to '/listings'"}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='staff_listing_slider_buy_link'
                  label='Buy - Versatile/Minimal sites only'
                  Input={TextInput}
                  optional={true}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='staff_listing_slider_rent_link'
                  label='Rent - Versatile/Minimal sites only'
                  Input={TextInput}
                  optional={true}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='staff_listing_slider_sold_link'
                  label='Sold - Versatile/Minimal sites only'
                  Input={TextInput}
                  optional={true}
                />
              </Box>

              <Box padding='10px' flex={1}>
                <Field
                  name='staff_listing_slider_leased_link'
                  label='Leased - Versatile/Minimal sites only'
                  Input={TextInput}
                  optional={true}
                />
              </Box>
            </Form>
          )}
        </ReactForms>
      </RenderLoading>
    </Box>
  );
}

export default compose(withToast, withQuery(settingQuery))(StaffPageInfo);
