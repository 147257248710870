import React from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import { compose } from 'lodash/fp';
import { withModel } from '@rexlabs/model-generator';
import sessionsModel from 'data/models/custom/session';

import IFrame from 'view/components/laravel-frame-screen';

const defaultStyles = StyleSheet({
  iframe: {
    width: '100%',
    height: '100vh'
  },
  sidemenu: {
    width: '20%',
    height: '100vh'
  }
});

function LaravelIframe({ styles: s, session, ...props }) {
  return <IFrame {...s('iframe')} {...props} />;
}

export default compose(
  styled(defaultStyles),
  withModel(sessionsModel)
)(LaravelIframe);
